<template lang="html">
  <section class="view-faculty">
    <div class="container" v-if="this.store.faculties[this.faculty]">
      <h1>{{ this.store.faculties[this.faculty]["name"] }}</h1>
      <router-link :to="{
        name: 'photos',
        params: {
          faculty: this.faculty,
          titulation: titulation.slug
        },
      }" :title="`Ver orlas de ${titulation.name}`" class="link-titulation"
        v-for="(titulation, key) in this.store.faculties[this.faculty]['titulations']" :key='key'>
        <span>{{ titulation.name }}</span>
      </router-link>
    </div>
  </section>
</template>

<script lang="js">
import { useFacultiesStore } from '@/store/faculties'

export default {
  name: 'view-facultad',
  props: ['faculty'],
  setup() {
    const store = useFacultiesStore();

    return {
      store
    }
  }
}
</script>